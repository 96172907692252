.phone-note {
    box-sizing: border-box;
    border: 1px solid #BEE5EB;
    border-radius: 4px;
    background-color: #D1ECF1;
    color: #0C5460;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    padding: 0.75rem;
}

.phone-note span {
    font-family: var(--font-bold);
}
