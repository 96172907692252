.message-rates {
    color: #707070;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
}

.got-it {
    color: #218739;
    line-height: 23px;
    font-family: var(--font-bold);
}
