:root {
	--font-family-sans-serif: 'f37_moonregular', sans-serif;
	--font-bold: 'f37_moondemi';
	--font-bolder: 'f37_moonextra_bold';
	--font-boldest: 'f37_moonblack';
	--font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

@font-face {
  font-family: 'f37_moonregular';
  src: url('/src/fonts/f37moon-regular-webfont.woff2') format('woff2'),
       url('/src/fonts/f37moon-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'f37_moondemi';
  src: url('/src/fonts/f37moon-demi-webfont.woff2') format('woff2'),
       url('/src/fonts/f37moon-demi-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'f37_moonblack';
  src: url('/src/fonts/f37moon-black-webfont.woff2') format('woff2'),
       url('/src/fonts/f37moon-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'f37_moonextra_bold';
  src: url('/src/fonts/f37moon-extrabold-webfont.woff2') format('woff2'),
       url('/src/fonts/f37moon-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.App {
  background-color: #440099;
  color: #2D2926;
  line-height: 1.5;
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

main {
  flex: 1 1 auto;
  justify-content: center;
  display: flex;
  align-items: center;
}

.center-wrapper {
  flex: 1;
  max-width: 397px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  padding: 2rem;
}

nav a {
  text-decoration: none;
  border: none;
}

.look-up-your-members {
  height: 5.4rem;
  width: 24.8rem;
  font-family: var(--font-boldest);
  font-size: 3rem;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 43.2px;
  text-align: center;
}

.verification-options-wrapper {
  border-radius: 12px;
  margin-top: 1.75rem;
  padding: 2rem;
  background-color: #FFFFFF;
}

.heading{
  font-family: var(--font-boldest);
  color: #2D2926;
  font-size: 2rem;
  letter-spacing: 0;
  line-height: 27.36px;
}

.button, button {
  height: 2.7rem;
  width: 20.8rem;
  cursor: pointer;
  background-color: #6E38D5;
  border-color: #6E38D5;
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid #6E38D5;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 1.43rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: var(--font-bold);
  margin: 5px 0px 5px 0px;
}

html, body, header, section, footer {
	font-family: 'f37_moonregular', sans-serif;
}
strong, h1, h2, h3 {
	font-family: 'f37_moondemi';
	font-weight: normal;
}

.text-thin {
	 font-weight: 300;
}

footer.main .nav > li:last-child > a {
    display: inherit;
}

p {
  margin-top: 0rem;
  margin-bottom: 1rem;
}

.text-white{
  color: #FFFFFF;
}

.text-aqua{
  color: #2ED7DE;
}

.mb-1{
  margin-bottom: 1rem;
}

.mt-1{
  margin-top: 1rem;
}

input{
  padding-left: 1rem;
  font-size: 1rem;
  font-family: var(--font-family-sans-serif);
}

.react-tel-input .form-control{
  width: 100% !important;
}

.error {
  color: red;
}

input.error{
  border-width: 1px 2px 1px 2px;
  border-color: #E71321;
  color: black;
  margin-bottom: 0.5rem;
}

.validation-error{
  color: #E71321;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: left;
}

input:focus-visible, input:focus{
  border: 3px solid rgba(110,56,213,0.5) !important;
  border-radius: 4px !important;
  outline: 0px !important;
  box-shadow:inset 0px 0px 0px 1px #6E38D5 !important;
}

.back{
  background-color: rgba(98,68,187,0);
  border-color: rgba(98,68,187,0);
  color: #6E38D5;
  font-family: var(--font-bolder);
}

.icon-check{
  background: url('../public/icon-check.png');
  height: 12px;
  width: 16px;
  display: inline-flex;
  margin-right: 5px;
}