footer a {
    color: #FFFFFF;
}

footer p {
    font-size: 1rem;
    color: #FFFFFF;
    text-align: center;
    font-family: var(--font-family-sans-serif);
}
