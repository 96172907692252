.all-verification-options {
    color: #6E38D5;
    font-family: var(--font-family-sans-serif);
    font-size: 1rem;
}

.all-verification-options span {
    line-height: 1;
    vertical-align: middle;
}

.chevron {
    font-size: 1.5rem;
    font-family: var(--font-bold);
    color: #6E38D5;
}

.verification-options-wrapper a {
    text-decoration: none;
}
