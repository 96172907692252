header {
    background-color: #221551;
    opacity: 0.9;
    width: 100%;
    height: 5.2rem;
    top: 0;
}

header .logo {
    padding: 1.5rem;
    display: block;
}
