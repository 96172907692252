.verification-options {
    height: 3.4rem;
    width: 20.8rem;
    color: #2D2926;
    font-family: var(--font-boldest);
    font-size: 30.4px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 27.36px;
}

.verify-your-anytime {
    height: 3rem;
    width: 20.8rem;
    color: #2D2926;
    font-family: var(--font-bold);
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 24px;
}
