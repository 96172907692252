section.main {
    display: flex;
    width: 20.8rem;
    padding-bottom: 4rem;
}

div.info-section {
    flex: 2;
    height: 218px;
    color: #2D2926;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-right: 1.5rem;
}

div.keyfob-section {
    flex: 1;
}

.keyfob-section img {
    max-width: 100%;
}

:global(.full-width div.input-container) {
    width: 100%;
    display: block;
}

.button-shifter {
    display: flex;
    flex-direction: column-reverse;
}

:global(.full-width > *) {
    margin-bottom: 0.5rem;
}

:global(.full-width > *:last-child) {
    margin-bottom: 0rem;
}

p.err-msg {
    color: red;
    padding: 0 1em;
    text-align: center;
}

input {
    box-sizing: border-box;
    height: 45px;
    width: 333px;
    border: 1px solid #D8D9D9;
    border-radius: 4px;
    background-color: #FFFFFF;
}

p strong {
    font-family: var(--font-bolder);
}

.required {
    color: #707070;
    font-family: var(--font-family-sans-serif);
    font-size: 13px;
    margin-top: 1.5rem;
}

label {
    font-family: var(--font-bold);
}
